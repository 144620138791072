<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <page-header/>
      <!-- HERO -->
      <section>
        <div class="uk-container uk-margin-large-bottom">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a href>{{$t("organization.category.home")}}</a>
              </li>
              <li>
                <span v-if="pagetype ==='main'">{{$t("organization.category.mCate")}}</span>
                <span v-else>{{$t("organization.category.sCate")}}</span>
              </li>
            </ul>
            <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
          </div>
          <div class="uk-width-1-1 uk-width-expand@m uk-margin-medium-top">
            <div class="table_wrap">
              <div class="uk-flex">
                <div class="sub_fwrap1">
                  <input type="text" class="ip_sort1" v-model="cateName">
                  <button type="button" v-on:click="cateAction(cateName, '1', '0', '')" class="btn_search2">
                    {{$t("additionOne.add")}}
                  </button>
                </div>
                <div class="uk-search uk-search-default uk-margin-large-left">
                  <span uk-search-icon></span>
                  <input class="uk-search-input uk-search-large" type="search"
                         :placeholder="$t('catesearch')" @keyup.enter="loadCates"
                         v-model="searchCate">
                </div>
              </div>
              <table id="sortertable" class="table001 uk-table uk-margin-small">
                <thead>
                  <tr>
                    <th scope="col" class="th01 w05_2">{{$t("organization.category.mUni")}}</th>
                    <th scope="col" class="th01 w05_2">{{$t("organization.category.mDep")}}</th>
                    <th scope="col" class="th01 w05_2">{{$t("organization.category.lGrades")}}</th>
                  </tr>
                </thead>
                <tbody v-if="categories.length > 0" id="tableResultList">
                <template v-for="(item, index) in categories">
                  <tr :key="index">
                    <td class="td01">
                        <div v-if="item.uId == currentUser.id">
                          <div class="code_wrap2" v-if="item.isEdit">
                            <input type="text" class="ip_code03" v-model="item.name">
                            <button type="button" v-on:click="update(item)" class="btn_code01"><span
                                uk-icon="icon: file-edit; ratio: 1.0"></span></button>
                            <button type="button" v-on:click="remove(item)" class="btn_code05"><span
                                uk-icon="icon: trash; ratio: 1.0"></span></button>
                            <button type="button" v-on:click="close(item)" class="btn_code03">X</button>
                          </div>
                          <div v-if="!item.isEdit" v-on:click="editName(item)"
                               class="code_wrap2_1">{{item.name}}</div>
                        </div>
                        <div v-else>
                          {{item.name}}
                        </div>
                    </td>
                    <td class="td01">
                      <div class="code_wrap2">
                        <input type="text" class="ip_code03" v-model="item.middleCateName"/>
                        <button type="button" v-on:click="cateAction(item.middleCateName, '2', item.id, item)" class="btn_code02">
                          <span uk-icon="icon: plus; ratio: 1.0"></span>
                        </button>
                      </div>
                    </td>
                    <td class="td01"></td>
                  </tr>
                  <template v-for="(itemm,inttem) in item.middlecates">
                    <tr :key="inttem">
                      <td class="td01"></td>
                      <td class="td01">
                        <div v-if="itemm.uId == currentUser.id">
                          <div v-show="!itemm.isEdit" v-on:click="editName(itemm)" class="code_wrap3">{{itemm.name}}
                          </div>
                          <div v-show="itemm.isEdit" class="code_wrap2">
                            <input type="text" class="ip_code03 upinput260" v-model="itemm.name"/>
                            <button type="button" v-on:click="update(itemm)" class="btn_code01"><span
                                uk-icon="icon: file-edit; ratio: 1.0"></span></button>
                            <button type="button" v-on:click="remove(itemm)" class="btn_code05"><span
                                uk-icon="icon: trash; ratio: 1.0"></span></button>
                            <button type="button" v-on:click="close(itemm)" class="btn_code03">X</button>
                          </div>
                        </div>
                        <div v-else>
                          {{itemm.name}}
                        </div>
                      </td>
                      <td class="td01">
                        <div class="code_wrap2">
                          <input type="text" class="ip_code03" v-model="itemm.lowCateName"/>
                          <button type="button"
                                  v-on:click="cateAction(itemm.lowCateName, '3', itemm.id, itemm)"
                                  class="btn_code02">
                            <span uk-icon="icon: plus; ratio: 1.0"></span>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <template v-for="(itemmm,seconkey) in itemm.lowcates">
                      <tr :key="seconkey">
                        <td class="td01"></td>
                        <td class="td01"></td>
                        <td class="td01">
                          <div v-if="itemmm.uId == currentUser.id">
                            <div v-show="!itemmm.isEdit" class="code_wrap3" id="angname261" v-on:click="editName(itemmm)">
                              {{itemmm.name}}
                            </div>
                            <div v-show="itemmm.isEdit" class="code_wrap2">
                              <input type="text" class="ip_code03" v-model="itemmm.name">
                              <button type="button" v-on:click="update(itemmm)" class="btn_code01"><span
                                  uk-icon="icon: file-edit; ratio: 1.0"></span></button>
                              <button type="button" v-on:click="remove(itemmm)" class="btn_code05"><span
                                  uk-icon="icon: trash; ratio: 1.0"></span></button>
                              <button type="button" v-on:click="close(itemmm)" class="btn_code03">X</button>
                            </div>
                          </div>
                          <div v-else class="code_wrap3">
                            {{itemmm.name}}
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="td01" colspan="8">Empty.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <page-footer/>
    </div>
  </layout>
</template>


<script scoped>
  import Layout from '../../layouts/Layout'
  import CategoryDataService from "../../services/CategoryDataService";
  import router from "../../router/index"
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
  import {mapGetters} from "vuex";
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")


  export default {
    name: 'Home',
    components: {
      Layout,
      PageHeader,
      PageFooter
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    },
    data() {
      return {
        cateName: '',
        categories: [],
        pagetype: 'main',
        searchCate: ''
      }
    },
    watch: {
      $route() {
        this.pagetype = this.$route.params.pagetype;
        if (this.pagetype !== 'main' && this.pagetype !== 'subject') {
          router.go(-1);
          return;
        }
        this.loadCates()
      }

    },

    filters: {},

    created() {
        console.log("this.currentUser")
        console.log(this.currentUser)
    },
    async mounted() {
      this.loadCates()
    },
    methods: {
      async cateAction(catename, cateStep, parentId, item) {
        if (catename.length == 0) {
          alert("please enter category name");
          return;
        }
        try {
          const response = await CategoryDataService.actionCategory({
            mode: "insert",
            cateStep: cateStep,
            cateCode: parentId,
            cateName: catename,
            type: this.pagetype

          })
          console.log(response.data)
          if (response.data.status === 200) {

            if (response.data.success == false) {
              alert(response.data.message)
              if (item != '') {
                if (item.cateStep == 1)
                  item.middleCateName = ""
                if (item.cateStep == 2)
                  item.lowCateName = ""
              }
            } else {
              this.loadCates()
            }
            if (parentId == '0') {
              this.cateName = ""
            }
          } else {
            alert("ERROR: " + response.data.message)
          }
        } catch (error) {
          console.log(error)
        }

      },

      editName(item) {
        item.isEdit = true
      },
      close(item) {
        item.isEdit = false
      },

      async remove(item) {

        try {
          const response = await CategoryDataService.actionCategory({
            mode: "delete",
            cateStep: item.cateStep,
            cateCode: item.id,
            cateName: item.name,
            type: this.pagetype

          })
          console.log(response.data)
          if (response.data.status === 200) {

            this.loadCates()

          } else {
            alert("ERROR: " + response.data.message)
          }


        } catch (error) {
          console.log(error)
        }

      },

      async update(item) {

        try {
          const response = await CategoryDataService.actionCategory({
            mode: "update",
            cateStep: item.cateStep,
            cateCode: item.id,
            cateName: item.name,
            type: this.pagetype
          })
          if (response.data.status === 200) {
            if (response.data.success == false) {
              alert(response.data.message)
            } else {
              item.isEdit = false
            }
          } else {
            alert("ERROR: " + response.data.message)
          }
        } catch (error) {
          console.log(error)
        }
      },
      async loadCates() {
        try {
          const response = await CategoryDataService.getCategories({
            type: this.pagetype,
            filter: this.searchCate
          })
          if (response.data.status === 200) {
            let maincates = []
            let middlecates = []
            let lowcates = []

            response.data.result.cates.forEach(item => {
              let cateStep = item.cateStep
              let name = item.name
              let id = item.id
              let parentId = item.parentId
              let uId = item.uId

              if (cateStep === 3 && parentId !== 0) {
                let lowcate = {}
                lowcate.name = name
                lowcate.cateStep = cateStep
                lowcate.id = id
                lowcate.uId = uId
                lowcate.parentId = parentId
                lowcate.isEdit = false
                lowcates.push(lowcate)
              }


              if (cateStep === 2 && parentId !== 0) {
                var midcate = {}
                midcate.name = name
                midcate.cateStep = cateStep
                midcate.id = id
                midcate.uId = uId
                midcate.isEdit = false
                midcate.lowCateName = ''
                midcate.parentId = parentId
                midcate.lowcates = []
                middlecates.push(midcate)
              }

              if (cateStep === 1 && parentId === 0) {
                var mcate = {}
                mcate.name = name
                mcate.cateStep = cateStep
                mcate.id = id
                mcate.uId = uId
                mcate.parentId = parentId
                mcate.isEdit = false
                mcate.middleCateName = ''
                mcate.middlecates = []
                maincates.push(mcate)
              }
            })
            middlecates.forEach(middle => {
              lowcates.forEach(low => {
                if (middle.id === low.parentId) {
                  middle.lowcates.push(low)
                }
              })
            })

            this.categories = []

            maincates.forEach(main => {
              middlecates.forEach(middle => {
                if (main.id === middle.parentId) {
                  main.middlecates.push(middle)
                }
              })
              this.categories.push(main)
            })
          } else {
            alert("ERROR: " + response.data.message)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>

<style scoped>
  .sub_fwrap1 {
    display: inline-block;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .sub_fwrap1 button.btn_search2:hover {
    transition: .7s;
    color: rgba(0, 89, 153, 1);
    background: rgba(176, 218, 251, .7);
    border: solid 1px rgba(9, 116, 201, 1);
  }

  .sub_fwrap1 .ip_sort1:hover {
    border: solid 1px rgba(0, 171, 216, 1);
    background: rgba(227, 248, 255, .6);
  }

  .sub_fwrap1 .ip_sort1 {
    display: inline-block;
    box-sizing: border-box;
    background: rgba(246, 252, 255, .5);
    width: 452px;
    text-align: left;
    text-indent: 10px;
    line-height: 40px;
    height: 40px;
    border: solid 1px rgba(83, 173, 243, 1);
    font-size: 15px;
    letter-spacing: 1px;
    color: rgba(61, 143, 212, 1);
    margin: 0;
    padding: 0;
    float: left;
  }

  .sub_fwrap1 button.btn_search2 {
    position: relative;
    left: -1px;
    display: inline-block;
    width: 180px;
    height: 40px;
    padding: 4px 0;
    text-align: center;
    text-indent: 0;
    font-size: 15px;
    line-height: 1;
    color: rgba(255, 255, 255, 1);
    float: left;
    margin: 0;
    background: rgba(9, 116, 200, 1);
    border: solid 1px rgba(9, 116, 201, 1);
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-sizing: border-box;
    letter-spacing: 0px;
    cursor: pointer;
  }

  .table001 .th01 {
    width: auto;
    height: auto;
    background: rgba(234, 234, 234, .7);
    font-size: 15px;
    color: rgba(63, 140, 205, 1);
    line-height: 15px;
    text-align: center;

  }

  .table_wrap .table001 {
    display: table;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-top: solid 1px rgba(83, 173, 243, 1);
    border-bottom: solid 1px rgba(83, 173, 243, .5);
  }

  .code_wrap2 .ip_code03:hover {
    border: solid 1px rgba(57, 155, 233, 1);
    background: rgba(227, 248, 255, .6);
  }

  .code_wrap2 button.btn_code02:hover {
    transition: .7s;
    background: rgba(0, 101, 181, 1);
    color: rgba(255, 255, 255, 1.00);
    border: solid 1px rgba(0, 101, 179, 1);
  }

  .code_wrap2 .ip_code03 {
    display: inline-block;
    box-sizing: border-box;
    background: rgba(246, 252, 255, .5);
    width: 140px;
    text-align: center;
    text-indent: 0px;
    line-height: 28px;
    height: 30px;
    border: solid 1px rgba(119, 192, 254, 1);
    font-size: 15px;
    letter-spacing: 0px;
    color: rgba(61, 143, 212, 1);
    margin: 0;
    padding: 0;
    float: left;
  }

  .code_wrap2 button.btn_code02 {
    position: relative;
    left: -1px;
    display: inline-block;
    width: 40px;
    height: 30px;
    padding: 4px 0;
    text-align: center;
    text-indent: 0;
    font-size: 13px;
    line-height: 1;
    background: rgba(58, 150, 229, 1);
    color: rgba(255, 255, 255, 1);
    float: left;
    margin: 0;
    border: solid 1px rgba(58, 150, 228, 1);
    border-radius: 0;
    box-sizing: border-box;
  }

  .table001 .td01 {
    width: auto;
    height: auto;
    margin: 0;
    padding: 8px 0 6px 0;
    background: rgba(255, 255, 255, 0);
    font-size: 15px;
    color: rgb(85, 85, 85);
    letter-spacing: -1px;
    line-height: 19px;
    border-top: solid 1px rgba(204, 204, 204, .7);
    text-align: center;
    word-spacing: 1px;
    height: 42px;
    box-sizing: border-box;
  }

  .table001 .code_wrap2 {
    display: table;
    margin: 0 0 0 25%;
    padding: 0;
    cursor: pointer;
    float: left;
  }

  .code_wrap2 button.btn_code02 {
    position: relative;
    left: -1px;
    display: inline-block;
    width: 40px;
    height: 30px;
    padding: 4px 0;
    text-align: center;
    text-indent: 0;
    font-size: 13px;
    line-height: 1;
    background: rgba(58, 150, 229, 1);
    color: rgba(255, 255, 255, 1);
    float: left;
    margin: 0;
    border: solid 1px rgba(58, 150, 228, 1);
    border-radius: 0;
    box-sizing: border-box;
  }

  .table001 .code_wrap2_1 {
    display: table;
    margin: 0 auto;
    padding: 0;
    cursor: pointer;
  }

  .table001 .code_wrap2_1:hover, .table001 .code_wrap:active {
    color: rgba(255, 116, 116, 1);
  }

  .code_wrap2 button.btn_code01:hover {
    transition: .7s;
    background: rgba(0, 101, 181, 1);
    color: rgba(255, 255, 255, 1.00);
    border: solid 1px rgba(0, 101, 179, 1);
  }

  .code_wrap2 button.btn_code01 {
    position: relative;
    left: -1px;
    display: inline-block;
    width: 40px;
    height: 30px;
    text-align: center;
    text-indent: 0;
    font-size: 13px;
    line-height: 1;
    background: rgba(119, 192, 255, 1);
    color: rgba(255, 255, 255, 1);
    float: left;
    margin: 0;
    border: solid 1px rgba(119, 192, 254, 1);
    border-radius: 0;
    box-sizing: border-box;
  }


  .code_wrap2 button.btn_code05:hover {
    transition: .7s;
    background: rgb(181, 34, 25);
    color: rgba(255, 255, 255, 1.00);
    border: solid 1px rgb(179, 29, 23);
  }

  .code_wrap2 button.btn_code05 {
    position: relative;
    left: -1px;
    display: inline-block;
    width: 40px;
    height: 30px;
    text-align: center;
    text-indent: 0;
    font-size: 13px;
    line-height: 1;
    background: rgb(255, 27, 44);
    color: rgba(255, 255, 255, 1);
    float: left;
    margin: 0;
    border: solid 1px rgb(254, 34, 51);
    border-radius: 0;
    box-sizing: border-box;
  }


  .code_wrap2 button.btn_code03 {
    position: relative;
    left: -1px;
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 4px 0;
    text-align: center;
    text-indent: 0;
    font-size: 13px;
    line-height: 1;
    background: rgba(201, 230, 255, 1);
    color: rgba(0, 104, 185, 1);
    float: left;
    margin: 0;
    border: solid 1px rgba(119, 192, 254, 1);
    border-radius: 0;
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
  }

  .table001 .code_wrap3:hover, .table001 .code_wrap:active {
    color: rgba(255, 116, 116, 1);
  }

  .table001 .code_wrap3 {
    display: table;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    cursor: pointer;
    background: url('../../assets/category.png') no-repeat 5% 30%;
    text-align: center;
  }
</style>
